export const Employees = [
    {
        image: 'https://advocaciadc.adv.br/db/images/employees/raphael.webp',
        name: 'Dr. Raphael Denippotti',
        jobTitle: 'OAB/SP 393888'
    },
    {
        image: 'https://advocaciadc.adv.br/db/images/employees/carlos.webp',
        name: 'Dr. Carlos Cristófano',
        jobTitle: 'OAB/SP 389858'
    },
    {
        image: 'https://advocaciadc.adv.br/db/images/employees/elizabeth.webp',
        name: 'Dra. Elizabeth Oliveira',
        jobTitle: 'OAB/SP 389572'
    },
    {
        image: 'https://advocaciadc.adv.br/db/images/employees/claudio.webp',
        name: 'Dr. Claudio Roberto',
        jobTitle: 'OAB/SP 405826'
    },
    {
        image: 'https://advocaciadc.adv.br/db/images/employees/micheli.webp',
        name: 'Micheli Nunes',
        jobTitle: 'Coordenadora de Relacionamento'
    },
    {
        image: 'https://advocaciadc.adv.br/db/images/employees/rafaela.webp',
        name: 'Rafaela Frailer Lago',
        jobTitle: 'Estagiária'
    },
    {
        image: 'https://advocaciadc.adv.br/db/images/employees/yasmin.webp',
        name: 'Yasmin Constantino',
        jobTitle: 'Estagiária'
    }
]