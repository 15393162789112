// Import React
import React, { useState, useEffect } from 'react';

// Import CSS
import './Header.css'

function Header() {
    // Define Redirects
    const handleRedirectTo = (url) => {
        window.open(url, "_blank");
    };

    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 850);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClick = (event, sectionId) => {
        event.preventDefault();

        const section = document.getElementById(sectionId);

        if (section) {
            section.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    return (
        <section id="header">
            <nav className="menu">
                <ul className="menu-web">
                    <li><a href="#areas-of-activity" onClick={(e) => handleClick(e, 'areas-of-activity')}>Áreas de Atuação</a></li>
                    <li><a href="#our-team" onClick={(e) => handleClick(e, 'our-team')}>Nossa Equipe</a></li>
                    <li><a href="#office" onClick={(e) => handleClick(e, 'office')}>O Escritório</a></li>
                    <li><a href="#social-networks" onClick={(e) => handleClick(e, 'social-networks')}>Redes Sociais</a></li>
                    <li><a href="#contact" onClick={(e) => handleClick(e, 'contact')}>Contato</a></li>
                </ul>
            </nav>

            <div className="content responsive">
                <img src="https://advocaciadc.adv.br/db/images/partners_header.webp" alt="denipotti & cristófano" />

                <div className="info">
                    <h1>
                        {isMobileView ? (
                            <>
                                Denipotti & Cristófano <br /> Sociedade de Advogados
                            </>
                        ) : 'Denipotti & Cristófano - Sociedade de Advogados'}
                    </h1>
                    <h2>
                        Atendimento
                        <span style={{ color: '#C0BC91' }}> personalizado</span>,
                        <span style={{ color: '#C0BC91' }}> compreensivo</span> e
                        <span style={{ color: '#C0BC91' }}> eficiente!</span>
                    </h2>
                    <h4>
                        Em todos os nossos serviços, nos esforçamos para oferecer atendimento 
                        {!isMobileView && <br />}
                        personalizado, compreendendo as necessidades individuais de cada 
                        {!isMobileView && <br />}
                        cliente e fornecendo soluções jurídicas eficientes e eficazes.
                    </h4>

                    <button
                        className="fast-contact"
                        onClick={() => handleRedirectTo('https://wa.me/18988002990/?text=Ol%c3%a1!+Gostaria+de+iniciar+o+meu+atendimento.')}
                    >
                        Contato rápido via Whatsapp
                    </button>
                </div>
            </div>
        </section>
    );
}

export default Header;