// Import Styles
import './App.css';

// Import Features
import Header from './pages/Header/Header';
import AreasOfActivity from './pages/AreasOfActivity/AreasOfActivity';
import OurTeam from './pages/OurTeam/OurTeam';
import Office from './pages/Office/Office';
import SocialNetworks from './pages/SocialNetworks/SocialNetworks';
import Contact from './pages/Contact/Contact';
import Footer from './pages/Footer/Footer';

function App() {
    return (
        <div className="App">
            <Header />
            <AreasOfActivity />
            <OurTeam />
            <Office />
            <SocialNetworks />
            <Contact />
            <Footer />
        </div>
    );
}

export default App;
