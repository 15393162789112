// Import React
import React, { useState } from 'react';

// Import CSS
import './Form.css'

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const removeSpecialChars = (str) => str.replace(/^\s+|\s+$/g, '');
        const whatsappMessage = removeSpecialChars(`*Olá! Gostaria de iniciar o meu atendimento. Aqui estão as minhas informações:*%0A
        *Nome:* ${formData.name} %0A
        *Email:* ${formData.email} %0A
        *Telefone:* ${formData.phone} %0A
        *Mensagem:* ${formData.message}`);

        const phoneNumber = '18988002990';
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${whatsappMessage}`;

        window.open(whatsappUrl, '_blank');
    };

    const User = () => {
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.01" width="20" height="20" fill="#151C33" />
            <path d="M9.99999 9.16667C11.8409 9.16667 13.3333 7.67428 13.3333 5.83333C13.3333 3.99238 11.8409 2.5 9.99999 2.5C8.15904 2.5 6.66666 3.99238 6.66666 5.83333C6.66666 7.67428 8.15904 9.16667 9.99999 9.16667Z" fill="#151C33" />
            <path d="M15 17.4999C15.4602 17.4999 15.8333 17.1268 15.8333 16.6666C15.8333 13.4449 13.2217 10.8333 9.99999 10.8333C6.77833 10.8333 4.16666 13.4449 4.16666 16.6666C4.16666 17.1268 4.53975 17.4999 4.99999 17.4999H15Z" fill="#151C33" />
        </svg>
    }

    const Email = () => {
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.01" width="20" height="20" fill="#151C33" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8333 3.33325H4.16666C2.78594 3.33325 1.66666 4.45254 1.66666 5.83325V14.1666C1.66666 15.5473 2.78594 16.6666 4.16666 16.6666H15.8333C17.214 16.6666 18.3333 15.5473 18.3333 14.1666V5.83325C18.3333 4.45254 17.214 3.33325 15.8333 3.33325ZM15.8333 4.99992L10.4167 8.72492C10.1588 8.87378 9.84116 8.87378 9.58332 8.72492L4.16666 4.99992H15.8333Z" fill="#151C33" />
        </svg>
    }

    const Phone = () => {
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.01" width="20" height="20" fill="#1A1A1A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 18.3333C7.41614 18.3241 1.67584 12.5838 1.66666 5.49993C1.66666 3.38284 3.3829 1.6666 5.49999 1.6666C5.71527 1.66496 5.9302 1.6845 6.14166 1.72493C6.34608 1.75518 6.54705 1.80542 6.74166 1.87493C7.02015 1.97265 7.22618 2.21038 7.28332 2.49993L8.42499 7.49993C8.48752 7.7757 8.40594 8.06435 8.20832 8.2666C8.09999 8.38327 8.09166 8.3916 7.06666 8.92493C7.88749 10.7256 9.32766 12.1717 11.125 12.9999C11.6667 11.9666 11.675 11.9583 11.7917 11.8499C11.9939 11.6523 12.2826 11.5707 12.5583 11.6333L17.5583 12.7749C17.8384 12.8399 18.0651 13.0446 18.1583 13.3166C18.2287 13.5144 18.2816 13.7179 18.3167 13.9249C18.3502 14.1344 18.3669 14.3462 18.3667 14.5583C18.3347 16.6656 16.6075 18.3518 14.5 18.3333Z" fill="#151C33" />
        </svg>
    }

    const Message = () => {
        return <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.01" x="0.416656" width="20" height="20" fill="#151C33" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.58331 2.5H16.25C17.6307 2.5 18.75 3.61929 18.75 5V13.3333C18.75 14.714 17.6307 15.8333 16.25 15.8333H7.54165C7.38081 15.8271 7.22162 15.8677 7.08331 15.95L3.34165 18.2167C3.21302 18.293 3.06622 18.3333 2.91665 18.3333C2.77349 18.3329 2.63286 18.2956 2.50831 18.225C2.24624 18.0777 2.08383 17.8006 2.08331 17.5V5C2.08331 3.61929 3.2026 2.5 4.58331 2.5ZM6.24998 9.16667C6.24998 9.6269 6.62308 10 7.08331 10C7.54355 10 7.91665 9.6269 7.91665 9.16667C7.91665 8.70643 7.54355 8.33333 7.08331 8.33333C6.62308 8.33333 6.24998 8.70643 6.24998 9.16667ZM10.4166 10C9.95641 10 9.58331 9.6269 9.58331 9.16667C9.58331 8.70643 9.95641 8.33333 10.4166 8.33333C10.8769 8.33333 11.25 8.70643 11.25 9.16667C11.25 9.6269 10.8769 10 10.4166 10ZM12.9166 9.16667C12.9166 9.6269 13.2897 10 13.75 10C14.2102 10 14.5833 9.6269 14.5833 9.16667C14.5833 8.70643 14.2102 8.33333 13.75 8.33333C13.2897 8.33333 12.9166 8.70643 12.9166 9.16667Z" fill="#151C33" />
        </svg>
    }

    return (
        <form onSubmit={handleSubmit}>
            <h1>Dúvidas? Fale com nosso time de especialistas</h1>
            <div className="custom-input">
                <User />
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Nome"
                    required
                />
            </div>

            <div className="custom-input">
                <Email />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                />
            </div>

            <div className="custom-input">
                <Phone />
                <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Telefone para contato"
                    required
                />
            </div>

            <div className="custom-input message">
                <Message />
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Descreva brevemente a sua necessidade"
                    maxLength={330}
                    required
                ></textarea>
            </div>

            <button type="submit">Enviar solicitação via whatsapp</button>
        </form>
    );
};

export default ContactForm;
