// Import React
import React, { useState, useEffect } from 'react';

// Import CSS
import './SectionTitle.css'

function SectionTitle({ arrows, title, subtitle, reverse }) {
    // Define CSS attributes
    const flexDirection = reverse ? 'column-reverse' : 'column';

    // Define SVGs
    const ArrowLeft = () => {
        return <svg width="107" height="12" viewBox="0 0 107 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M106 6.99999C106.552 6.99999 107 6.55228 107 5.99999C107 5.44771 106.552 4.99999 106 4.99999L106 6.99999ZM0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM106 4.99999L6 5L6 7L106 6.99999L106 4.99999Z" fill="#C0BC91" />
        </svg>
    }

    const ArrowRight = () => {
        return <svg width="107" height="12" viewBox="0 0 107 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 5C0.447716 5 0 5.44772 0 6C0 6.55228 0.447716 7 1 7V5ZM106.774 6L101 0.226497L95.2265 6L101 11.7735L106.774 6ZM1 7L101 7V5L1 5V7Z" fill="#C0BC91" />
        </svg>
    }

    const ArrowRightForTablet = () => {
        return <svg width="72" height="12" viewBox="0 0 72 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 5C0.447717 5 1.43051e-06 5.44772 1.43051e-06 6C1.43051e-06 6.55228 0.447717 7 1 7V5ZM71.7735 6L66 0.226497L60.2265 6L66 11.7735L71.7735 6ZM1 7L66 7V5L1 5V7Z" fill="#C0BC91" />
        </svg>
    }

    const ArrowLeftForTablet = () => {
        return <svg width="72" height="12" viewBox="0 0 72 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M71 7.00001C71.5523 7.00001 72 6.5523 72 6.00001C72 5.44773 71.5523 5.00001 71 5.00001L71 7.00001ZM0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM71 5.00001L6 5L6 7L71 7.00001L71 5.00001Z" fill="#C0BC91" />
        </svg>
    }

    const ArrowRightForMobile = () => {
        return <svg width="37" height="12" viewBox="0 0 37 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 4.93811C0.447716 4.93811 1.95563e-06 5.38582 1.90735e-06 5.93811C1.85907e-06 6.49039 0.447716 6.93811 1 6.93811L1 4.93811ZM36.7735 5.93811L31 0.164608L25.2265 5.93811L31 11.7116L36.7735 5.93811ZM1 6.93811L31 6.93811L31 4.93811L1 4.93811L1 6.93811Z" fill="#C0BC91" />
        </svg>
    }

    const ArrowLeftForMobile = () => {
        return <svg width="37" height="12" viewBox="0 0 37 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36 6.93811C36.5523 6.93811 37 6.49039 37 5.93811C37 5.38582 36.5523 4.93811 36 4.93811L36 6.93811ZM0.226497 5.93811L6 11.7116L11.7735 5.93811L6 0.164608L0.226497 5.93811ZM36 4.93811L6 4.93811L6 6.93811L36 6.93811L36 4.93811Z" fill="#C0BC91" />
        </svg>
    }

    // Saves the current screen width value
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isSmallScreen = windowWidth < 820;
    const isVerySmallScreen = windowWidth < 540;

    return (
        <div className={`section-title ${arrows}`} style={{ flexDirection }}>
            <h1>
                {(arrows === 'left' || arrows === 'both') && (
                    isVerySmallScreen ? <ArrowLeftForMobile /> :
                        isSmallScreen ? <ArrowLeftForTablet /> :
                            <ArrowLeft />
                )}
                {title}
                {(arrows === 'right' || arrows === 'both') && (
                    isVerySmallScreen ? <ArrowRightForMobile /> :
                        isSmallScreen ? <ArrowRightForTablet /> :
                            <ArrowRight />
                )}
            </h1>
            <h4>
                {subtitle}
            </h4>
        </div>
    );
}

export default SectionTitle;