// Import React
import React from 'react';

// Import CSS
import './SocialNetworks.css'

// Import Components
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import InstagramFeed from '../../components/Instagram/Instagram';

function SocialNetworks() {
    return (
        <section id="social-networks">
            <div className="content padding responsive">
                <SectionTitle
                    arrows="left"
                    title="Redes Sociais"
                    subtitle="Informações diárias para você"
                    reverse={true}
                />

                <div className="social">
                    <InstagramFeed />
                </div>
            </div>
        </section>
    );
}

export default SocialNetworks;