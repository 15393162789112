// Import React
import React from 'react';

// Import CSS
import './AreasOfActivity.css'

// Import Data
import { Activities } from '../../data/areasOfActivity';

// Import Components
import SectionTitle from '../../components/SectionTitle/SectionTitle';

function AreasOfActivity() {
    return (
        <section id="areas-of-activity">
            <div className="content padding responsive">
                <SectionTitle
                    arrows="both"
                    title="Áreas de Atuação"
                    subtitle="Ações que nos movem diariamente"
                    reverse={false}
                />

                <div className="areas">
                    {Activities.map((activity, index) => {
                        return (
                            <div className="area" key={index}>
                                {activity.icon}
                                <h1>{activity.area}</h1>
                                <h4>{activity.description}</h4>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    );
}

export default AreasOfActivity;