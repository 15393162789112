// Import React
import React from 'react';

// Import CSS
import './OfficeData.css'

function ContactInfo({ title, details }) {
    // Define SVGs
    const Arrow = () => {
        return <svg width="35" height="12" viewBox="0 0 35 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34 7C34.5523 7 35 6.55228 35 6C35 5.44772 34.5523 5 34 5V7ZM0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM34 5H6V7H34V5Z" fill="#C0BC91" />
        </svg>
    }

    return (
        <div className="office-contact">
            <h1><Arrow /> {title}</h1>
            <div className="data-contact">
                {details.map((data, index) => (
                    <div className="specific-contact" key={index}>
                        {data.icon}
                        <div className="infos">
                            <h2>{data.title}</h2>
                            <h3>{data.info}</h3>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ContactInfo;