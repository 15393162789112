// Import React
import React from 'react';

// Import CSS
import './Office.css'

// Import Components
import SectionTitle from '../../components/SectionTitle/SectionTitle';

function Office() {
    return (
        <section id="office">
            <div className="content padding responsive">
                <SectionTitle
                    arrows="right"
                    title="O Escritório"
                    subtitle="Um pouco da nossa história"
                    reverse={true}
                />

                <div className="about-office">
                    <img src="https://advocaciadc.adv.br/db/images/partners.webp" alt="Denipotti & Cristófano" />

                    <h6>
                        <p>
                            Denippotti & Cristófano Sociedade de Advogados é um escritório especializado em Direito do Trabalho,
                            com foco no trabalhador bancário. Nossa equipe experiente e dedicada está pronta para oferecer
                            suporte jurídico abrangente, garantindo que os direitos e interesses dos trabalhadores sejam protegidos.
                        </p>

                        <p>
                            Além de nossa expertise em Direito do Trabalho, também somos especialistas em direito digital e na
                            Lei Geral de Proteção de Dados (LGPD). Com o avanço tecnológico e a crescente importância da privacidade,
                            fornecemos consultoria e orientação jurídica para questões relacionadas à proteção de dados, privacidade
                            online, segurança cibernética e conformidade com a LGPD.
                        </p>

                        <p>
                            Outra área de destaque do nosso escritório é a legislação sobre armas. Nossos advogados têm amplo conhecimento
                            e experiência nesse campo, oferecendo assistência legal especializada para questões relacionadas à posse e ao
                            porte de armas de fogo, licenças e autorizações.
                        </p>

                        <p>
                            No âmbito do direito civil e processo civil, temos ênfase na responsabilidade civil e no direito do consumidor.
                            Representamos nossos clientes em disputas civis, oferecendo suporte tanto para casos de responsabilidade civil,
                            envolvendo danos materiais e morais, quanto para questões relacionadas aos direitos do consumidor, como ações
                            de indenização, revisão de contratos e orientação sobre práticas comerciais abusivas.
                        </p>

                        <p>
                            Em todos os nossos serviços, nos esforçamos para oferecer atendimento personalizado, compreendendo as
                            necessidades individuais de cada cliente e fornecendo soluções jurídicas eficientes e eficazes. Nossa
                            equipe está comprometida em alcançar os melhores resultados possíveis para aqueles que confiam em nossos serviços.
                        </p>
                    </h6>
                </div>
            </div>
        </section>
    );
}

export default Office;