// Import React
import React from 'react';

// Import CSS
import './Footer.css'

function Footer() {
    // Define Redirects
    const handleRedirectTo = (url) => {
        window.open(url, "_blank");
    };

    // Define Current Year
    const currentYear = new Date().getFullYear();

    // Define SVGs
    const FacebookIcon = () => {
        return <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 3H6.5C5.9477 3 5.5 3.44772 5.5 4V7H9.5C9.6137 6.99748 9.7216 7.0504 9.7892 7.1419C9.8568 7.2334 9.8758 7.352 9.84 7.46L9.1 9.66C9.0318 9.8619 8.8431 9.9984 8.63 10H5.5V17.5C5.5 17.7761 5.2761 18 5 18H2.5C2.22386 18 2 17.7761 2 17.5V10H0.5C0.22386 10 0 9.7761 0 9.5V7.5C0 7.2239 0.22386 7 0.5 7H2V4C2 1.79086 3.7909 0 6 0H9.5C9.7761 0 10 0.22386 10 0.5V2.5C10 2.77614 9.7761 3 9.5 3Z" fill="white" />
        </svg>
    }

    const InstagramIcon = () => {
        return <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.3333 0H5.33331C2.57189 0 0.333313 2.23858 0.333313 5V13C0.333313 15.7614 2.57189 18 5.33331 18H13.3333C16.0947 18 18.3333 15.7614 18.3333 13V5C18.3333 2.23858 16.0947 0 13.3333 0ZM16.5833 13C16.5778 14.7926 15.1259 16.2445 13.3333 16.25H5.33331C3.54066 16.2445 2.0888 14.7926 2.08331 13V5C2.0888 3.20735 3.54066 1.75549 5.33331 1.75H13.3333C15.1259 1.75549 16.5778 3.20735 16.5833 5V13ZM14.0833 5.25C14.6356 5.25 15.0833 4.80228 15.0833 4.25C15.0833 3.69772 14.6356 3.25 14.0833 3.25C13.531 3.25 13.0833 3.69772 13.0833 4.25C13.0833 4.80228 13.531 5.25 14.0833 5.25ZM9.33331 4.5C6.84803 4.5 4.83331 6.51472 4.83331 9C4.83331 11.4853 6.84803 13.5 9.33331 13.5C11.8186 13.5 13.8333 11.4853 13.8333 9C13.836 7.8057 13.3627 6.65957 12.5182 5.81508C11.6737 4.97059 10.5276 4.49734 9.33331 4.5ZM6.58331 9C6.58331 10.5188 7.81451 11.75 9.33331 11.75C10.8521 11.75 12.0833 10.5188 12.0833 9C12.0833 7.4812 10.8521 6.25 9.33331 6.25C7.81451 6.25 6.58331 7.4812 6.58331 9Z" fill="white" />
        </svg>
    }

    const LinkedInIcon = () => {
        return <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.66669 0H16.6667C17.7713 0 18.6667 0.89543 18.6667 2V16C18.6667 17.1046 17.7713 18 16.6667 18H2.66669C1.56212 18 0.666687 17.1046 0.666687 16V2C0.666687 0.89543 1.56212 0 2.66669 0ZM5.66669 15C5.94283 15 6.16669 14.7761 6.16669 14.5V7.5C6.16669 7.2239 5.94283 7 5.66669 7H4.16669C3.89055 7 3.66669 7.2239 3.66669 7.5V14.5C3.66669 14.7761 3.89055 15 4.16669 15H5.66669ZM4.91669 6C4.08826 6 3.41669 5.32843 3.41669 4.5C3.41669 3.67157 4.08826 3 4.91669 3C5.74512 3 6.41669 3.67157 6.41669 4.5C6.41669 5.32843 5.74512 6 4.91669 6ZM15.1667 15C15.4428 15 15.6667 14.7761 15.6667 14.5V9.9C15.6992 8.3108 14.5243 6.95452 12.9467 6.76C11.8437 6.65925 10.775 7.1744 10.1667 8.1V7.5C10.1667 7.2239 9.94279 7 9.66669 7H8.16669C7.89059 7 7.66669 7.2239 7.66669 7.5V14.5C7.66669 14.7761 7.89059 15 8.16669 15H9.66669C9.94279 15 10.1667 14.7761 10.1667 14.5V10.75C10.1667 9.9216 10.8383 9.25 11.6667 9.25C12.4951 9.25 13.1667 9.9216 13.1667 10.75V14.5C13.1667 14.7761 13.3906 15 13.6667 15H15.1667Z" fill="white" />
        </svg>
    }

    // Define Icons and URLs
    const socials = {
        facebook: {
            icon: <FacebookIcon />,
            url: 'https://www.facebook.com/denippotticristofano'
        },
        instagram: {
            icon: <InstagramIcon />,
            url: 'https://www.instagram.com/denippotticristofano'
        },
        linkedIn: {
            icon: <LinkedInIcon />,
            url: 'https://www.linkedin.com/company/denippotticristofano/'
        }
    }

    return (
        <section id="footer">
            <div className="content responsive">
                <h6>Denippotti & Cristófano - Sociedade de Advogados © {currentYear}. Todos os direitos reservados.</h6>
                <div className="socials">
                    {Object.entries(socials).map(([key, social], index) => (
                        <div className="icon" key={index} onClick={() => handleRedirectTo(social.url)} style={{ cursor: 'pointer' }}>
                            {social.icon}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Footer;