// Import React
import React, { useEffect } from 'react';

// Import Swiper Components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode } from 'swiper/modules';

// Import CSS
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import './Instagram.css'

// Import Scripts
import Instafeed from 'instafeed.js';

const InstagramFeed = () => {
    const accessTokenOffice = 'IGQVJYRlBLSks1RHJPcEtJa2J3b0FCRndSTGIwTERGZAWllUVBKdXF3R1B4REpPQ2dRUlJIUFVyWGJWSGNoaVRaNDFLQWdGY2pIeUc0VHN0aDlFeFB6T3ktYmFsTWg1TnV5TmVJR19vdTMzUzZAfMjE2NQZDZD'
    const accessTokenBank = 'IGQVJWTWI5TXV3YnRxQ1liVE9yRmdmTVhoOHB1N3pBdmlTRElUZA2c3NTVEN1lwcHBlMzc1MU1hVGxYVDJlOGlRRnR4ZAFRTTGZAPTmJaQ28tdmxEV1BraHg2MVhfRjVRZAlhGSDNtMUQtdHpCZAWlQX2J2YwZDZD'

    useEffect(() => {
        const feed = new Instafeed({
            accessToken: accessTokenOffice,
            limit: 12,
            template: '<div class="item"><a href="{{link}}" target="_blank"><img title="{{caption}}" src="{{image}}" /></a></div>',
            target: 'instafeed',
        });

        const feedTwo = new Instafeed({
            accessToken: accessTokenBank,
            limit: 12,
            template: '<div class="item"><a href="{{link}}" target="_blank"><img title="{{caption}}" src="{{image}}" /></a></div>',
            target: 'instafeedtwo',
        });

        feed.run();
        feedTwo.run();
    }, []);

    const Arrow = () => {
        return <svg width="102" height="12" viewBox="0 0 102 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 5.00001C0.447718 5.00001 2.57432e-06 5.44772 2.6226e-06 6.00001C2.67089e-06 6.55229 0.447718 7.00001 1 7.00001L1 5.00001ZM101.774 6L96 0.226497L90.2265 6L96 11.7735L101.774 6ZM1 7.00001L96 7L96 5L1 5.00001L1 7.00001Z" fill="#C0BC91" />
        </svg>

    }

    return (
        <section id="instagram">
            <div className="social">
                <h3>@denippotticristofano <Arrow /></h3>
                <Swiper
                    loop={false}
                    slidesPerView={"auto"}
                    spaceBetween={20}
                    freeMode={true}
                    modules={[Navigation, FreeMode]}
                    className="mySwiper"
                >
                    <SwiperSlide style={{ width: 'auto' }}>
                        <div id="instafeed" className="item"></div>
                    </SwiperSlide>
                </Swiper>
            </div>

            <div className="social">
                <h3>@advogandoparabancarios <Arrow /></h3>
                <Swiper
                    loop={false}
                    slidesPerView={"auto"}
                    spaceBetween={20}
                    freeMode={true}
                    modules={[Navigation, FreeMode]}
                    className="mySwiper"
                >
                    <SwiperSlide style={{ width: 'auto' }}>
                        <div id="instafeedtwo" className="item"></div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    );
};

export default InstagramFeed;
